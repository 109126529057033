import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import Cookie from 'js-cookie';

const API_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
const CLIENT_ID = 'sYW0Gat69hmf94s7zfd6'; // TODO: Get from config
export const CLIENT_HEADERS = {
  'content-type': 'application/json',
  accept: 'application/json',
  'x-bb-clientid': CLIENT_ID,
  'x-bb-clienttype': 'ERP',
  'x-bb-version': 62,
  'x-bb-version-name': 1.62,
};

const authLink = setContext(async ({ operationName }, { headers }) => {
  const token = Cookie.get('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...CLIENT_HEADERS,
      'x-apollo-operation-name': operationName,
      ...headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    ApolloLink.split(
      (operation: any) => operation.getContext().clientName === 'NODE_GATEWAY',
      new HttpLink({
        uri: process.env.REACT_APP_NODE_GRAPHQL_URI,
        credentials: 'same-origin',
      }),
      new HttpLink({
        uri: API_GATEWAY_URL,
        credentials: 'same-origin',
      })
    )
  ),
  cache: new InMemoryCache(),
});

export default client;
