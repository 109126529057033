import gql from 'graphql-tag';

export const CLIENT_AUTH = gql`
  mutation clientAuth($id: ID!, $secret: String!) {
    clientAuth(input: { id: $id, secret: $secret }) {
      token
    }
  }
`;

export const LOGIN = gql`
  mutation loginV2($input: UserAuthInputV2!) {
    loginV2(input: $input) {
      token
      refreshToken
      bbDeviceId
      status
      userAccount {
        id
        permissions
        name
        email
        phone
      }
      isSignup
    }
  }
`;

export const INIT_DEVICE = gql`
  mutation initDeviceV2($input: InitDeviceInput!) {
    initDeviceV2(input: $input) {
      userToken
      userRefreshToken
      bbDeviceId
      loggedIn
      forceUpdateSpecificVersions
      refreshTime
      userAccount {
        id
      }
    }
  }
`;
