import firebase from 'firebase/app';
import 'firebase/auth';
import Cookie from 'js-cookie';

import client from '../apolloClient';

import { INIT_DEVICE, LOGIN } from '../graphql/mutations/auth';
import Device from '../graphql/services/Device';

const config = {
  apiKey: 'AIzaSyDMi8CUG7s2fwrkDCBvkgtX2o-SJxhsdg0',
  authDomain: 'test-web-app-feefc.firebaseapp.com',
  databaseURL: 'https://test-web-app-feefc.firebaseio.com',
  projectId: 'test-web-app-feefc',
  storageBucket: 'test-web-app-feefc.appspot.com',
  messagingSenderId: '3422175616',
};

firebase.initializeApp(config);

let visitorId: any;
const storedPermissions = localStorage.getItem('permissions');
const permissions: any =
  storedPermissions &&
  storedPermissions !== 'null' &&
  storedPermissions.length > 0
    ? JSON.parse(storedPermissions)
    : [];
let loginClick = 0;
const authProvider = {
  isLoggedIn: false,
  permissions,
  login: async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    loginClick = 1;
    await firebase.auth().signInWithPopup(provider);
  },
  logout: async () => {
    await firebase.auth().signOut();
    localStorage.removeItem('permissions');
    Cookie.remove('token');
    const prevLoginStatus = authProvider.isLoggedIn;
    authProvider.isLoggedIn = false;
    if (prevLoginStatus) window.location.reload();
  },
  checkAuth: async () => {
    if (loginClick) {
      loginClick = 0;
      return;
    }
    let token: string | undefined = Cookie.get('token');
    if (token) {
      return Promise.resolve(true);
    } else if (!token) {
      let user: any;
      await firebase.auth().onAuthStateChanged(async (u) => {
        user = u;
      });
      if (!user) {
        return Promise.reject(false);
      }
      // User is signed in.
      const idToken = await user.getIdToken();
      let clientAuthToken = null;
      if (!clientAuthToken) {
        const input: any = {
          clientSecret: 'VLfizd0oF6yCYlj75015TquGY3dW5k0xWlpAYibc',
        };

        const tokens = Device.getWebToken();

        if (tokens.userToken) {
          input.userToken = tokens.userToken;
        }
        if (tokens.userRefreshToken) {
          input.userRefreshToken = tokens.userRefreshToken;
        }
        const token = Cookie.get('token');
        const rttoken = Cookie.get('rt-token');
        if (token) {
          input.userToken = token;
        }
        if (rttoken) {
          input.userRefreshToken = rttoken;
        }
        const result = await client.mutate({
          variables: {
            input,
          },
          mutation: INIT_DEVICE,
          context: {
            clientName: 'GO_GATEWAY',
            headers: {
              'x-apollo-operation-name': 'initDeviceGoMutation',
              'x-bb-deviceid': visitorId,
            },
          },
        });
        if (result && result.data) {
          if (result.data.initDeviceV2.loggedIn) {
            Cookie.set('x-bb-user-token', result.data.initDeviceV2.userToken);
            Cookie.set(
              'x-bb-user-rt',
              result.data.initDeviceV2.userRefreshToken
            );
            Cookie.set('clientToken', result.data.initDeviceV2.userToken);
            Cookie.set('token', result.data.initDeviceV2.userToken, {
              expires: 7,
            });
            Cookie.set('rt-token', result.data.initDeviceV2.userRefreshToken, {
              expires: 7,
            });
            clientAuthToken = result.data.initDeviceV2.userToken;
          } else {
            // await firebase.auth().signOut();
            // localStorage.removeItem('permissions');
            // Cookie.remove('token');
            // authProvider.isLoggedIn = false;
            // window.location.reload();
          }
        }
      }
      const res: any = await client.mutate({
        mutation: LOGIN,
        variables: {
          input: {
            id: idToken,
            mode: 'GOOGLE',
          },
        },
        context: {
          clientName: 'GO_GATEWAY',
          headers: {
            Authorization: `Bearer ${token || clientAuthToken}`,
            'x-bb-clientid': 'sYW0Gat69hmf94s7zfd6',
            'x-bb-clienttype': 'ERP',
            'x-bb-version': 31,
            'x-bb-version-name': 1.54,
            'x-bb-deviceid': 'e26a124f0cd9a8b6',
          },
        },
      });
      if (res.data.loginV2.userAccount) {
        Cookie.set('token', res.data.loginV2.token, { expires: 7 });
        Cookie.set('rt-token', res.data.loginV2.refreshToken, {
          expires: 7,
        });
        token = res.data.loginV2.token;
        authProvider.permissions =
          res.data.loginV2.userAccount.permissions || [];
        localStorage.setItem(
          'permissions',
          JSON.stringify(authProvider.permissions)
        );
        window.location.reload();
        return Promise.resolve(true);
      }
    }
  },
  checkError: (error: any) => {
    console.error(error);
    Promise.resolve();
  },
  getPermissions: () => Promise.resolve(authProvider.permissions),
};

export default authProvider;
