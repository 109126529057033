import Cookie from 'js-cookie';

let cookieDomain =
  process.env.NODE_ENV === 'production' ? '.bulbul.tv' : 'localhost';

if (
  !process.env.SERVER_SIDE &&
  window &&
  !window.location.hostname.includes('bulbul')
) {
  cookieDomain = `.${window.location.hostname.replace('www.', '')}`;
}

if (cookieDomain === '.localhost') {
  cookieDomain = 'localhost';
}

export let AffiliateType = {
  isMX: false,
  isDailyHunt: false,
};

export default class Device {
  public static DEVICE = 'bb-device';
  public static DEVICE_ID = 'bb-device-id';
  public static LOCALE = 'bb-locale';
  public static locale = null;

  static getWebToken() {
    const userRefreshToken = Cookie.getJSON('x-bb-user-rt');
    let userToken = Cookie.getJSON('x-bb-user-token');

    if (!userRefreshToken) userToken = null;

    return {
      webToken: Cookie.getJSON('x-bb-web-token'),
      userRefreshToken,
      userToken,
    };
  }
  static getBBDeviceId() {
    return Cookie.getJSON('x-bb-custom-deviceid');
  }
}
