import React from 'react';
import ReactDOM from 'react-dom';
import Cookie from 'js-cookie';
import firebase from 'firebase/app';
import 'firebase/auth';

import './index.css';
import * as serviceWorker from './serviceWorker';
import { INIT_DEVICE } from './seller/graphql/mutations/auth';
import client from './seller/apolloClient';
import Device from './seller/graphql/services/Device';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import authProvider from './seo/auth';
let visitorId: any;

(async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  visitorId = result.visitorId;
})();

import(`./${process.env.REACT_APP_DASHBOARD}/App`).then((app) => {
  // init device
  (async () => {
    try {
      const input: any = {
        clientSecret: 'VLfizd0oF6yCYlj75015TquGY3dW5k0xWlpAYibc',
      };

      const tokens = Device.getWebToken();

      if (tokens.userToken) {
        input.userToken = tokens.userToken;
      }
      if (tokens.userRefreshToken) {
        input.userRefreshToken = tokens.userRefreshToken;
      }
      const token = Cookie.get('token');
      const rttoken = Cookie.get('rt-token');
      if (token) {
        input.userToken = token;
      }
      if (rttoken) {
        input.userRefreshToken = rttoken;
      }
      if (token) {
        const result = await client.mutate({
          variables: {
            input,
          },
          mutation: INIT_DEVICE,
          context: {
            clientName: 'goGateway',
            headers: {
              'x-apollo-operation-name': 'initDeviceGoMutation',
              'x-bb-deviceid': visitorId,
            },
          },
        });
        if (result && result.data) {
          if (result.data.initDeviceV2.loggedIn) {
            Cookie.set('x-bb-user-token', result.data.initDeviceV2.userToken);
            Cookie.set(
              'x-bb-user-rt',
              result.data.initDeviceV2.userRefreshToken
            );
            Cookie.set('clientToken', result.data.initDeviceV2.userToken);
            Cookie.set('token', result.data.initDeviceV2.userToken, {
              expires: 7,
            });
            Cookie.set('rt-token', result.data.initDeviceV2.userRefreshToken, {
              expires: 7,
            });
          } else {
            await firebase.auth().signOut();
            localStorage.removeItem('permissions');
            Cookie.remove('token');
            authProvider.isLoggedIn = false;
            window.location.reload();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  })();

  const App = app.default;
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
